<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-monitor-share</v-icon> Compartilhamento</v-card-title>
    <v-card-title class="py-0 ml-2">Listas</v-card-title>
    <v-card-text class="ma-2">
      <v-row no-gutters>
        <v-col class="pl-0 pr-4" cols="6">
          <div>
            <span class="pb-2">Cabeçalho</span>
            <v-textarea v-model="sharing.list.header" full-width outlined />
          </div>
        </v-col>
        <v-col class="px-0" cols="6">
          <div>
            <span class="pb-2">Rodapé</span>
            <v-textarea v-model="sharing.list.footer" full-width outlined />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" outlined @click="setDefaultSharingConfiguration"><v-icon class="mr-1 mb-1">mdi-note-text-outline</v-icon> Utilizar Padrão</v-btn>
      <v-btn color="primary" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Compartilhamento</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updateSharingList" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION, MUTATION_UPDATE_APP_SHARING } from '@/modules/accounts/graphql'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    accountName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: false,
    textDialog: 'Tem certeza que deseja atualizar as configurações de compartilhamento?',
    sharing: {
      list: {
        header: '',
        footer: ''
      }
    }
  }),
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update({ configuration }) {
        this.sharing.list.header = configuration?.sharing?.list?.header || ''
        this.sharing.list.footer = configuration?.sharing?.list?.footer || ''
      }
    }
  },
  methods: {
    setDefaultSharingConfiguration() {
      this.sharing.list.header = 'Olá! Uma lista de compras foi compartilhada com você.'
      this.sharing.list.footer = `Quer ter ofertas exclusivas? Instale o aplicativo do ${this.accountName} e confira todas as nossas ofertas:
-link ios: {store_links.app_store} 
-link android: {store_links.play_store} `
    },
    async updateSharingList() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_SHARING,
          variables: {
            account_id: this.accountId,
            header: this.sharing.list.header,
            footer: this.sharing.list.footer
          }
        })
        this.$snackbar({ message: 'Compartilhamento atualizado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar compartilhamento', snackbarColor: '#F44336' })
      }
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      this.$apollo.queries.configuration.refetch()
    }
  }
}
</script>
